import React from 'react';
import {
    Form, Divider, FlexboxGrid, IconButton, Icon, Radio, Badge
} from 'rsuite';

import { TextField, NumberField, CascadeSelectField, SelectField, MultiSelectField, RadioField, CodeEditorField, ToogleField, TextEditorField } from '../_common/form-fields';
import ImageUploader from "../_common/image-uploader"

import { Category } from './categories-const'
import {
    getSelectionValues, convertCategoriesForFilter, convertToTree,
    convertAttributesForFilter, convertAttributesCategoriesForFilter,
    genObjectsFromIds, genObjectFromId, generateFilterIDPrint, slugify,
    convertAttrAndCatsForFilter
} from '../../utils/utils'

import { cloneDeep } from 'lodash'



const CategoriesForm = ({
    category, categories, model,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    removeFormRow, addFormRow,
    attributes, attributes_categories,
    selected_website
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={category}
            >
                <Divider className="mt-1">Category General </Divider>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <MultiSelectField
                            name={"websites"}
                            placeholder="Select Associated Websites"
                            searchable={false}
                            data={available_websites}
                            labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Associated Websites"}
                            value={getSelectionValues(category.websites)}
                            onChange={(values, e) => {
                                //e.preventDefault();
                                category.websites = genObjectsFromIds(values, available_websites)
                                handleFormChange(category, ftype)
                            }}

                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 21 }}>
                        <RadioField name="status" className="inline-radio" appearance="picker" inline>
                            <span>Status: </span>
                            <Radio value={Category.Status.ACTIVE}>Active</Radio>
                            <Radio value={Category.Status.DRAFT}>Draft</Radio>
                            <Radio value={Category.Status.ARCHIVED}>Archived</Radio>
                        </RadioField>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="name" label="Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="display_name" label="Display Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="slug" label="URL Slug" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pt-2">
                        <IconButton
                            size="md"
                            placement="left"
                            onClick={() => {
                                category.slug = slugify(category.name) + (selected_website.category_slug_addition ? selected_website.category_slug_addition : "")
                                handleFormChange(category, ftype)
                            }}
                            icon={<Icon icon="magic" />}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="page_title" label="Page Title" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="canonical" label="Canonical URL slug" style={{ width: 200 }} />
                    </FlexboxGrid.Item>

                </FlexboxGrid>
                <FlexboxGrid className="pb-4">
                    <FlexboxGrid.Item style={{ paddingTop: 21 }}>
                        <ToogleField
                            name={`show_name`}
                            label={"Show name"}
                            style={{ width: 60 }}
                            value={category.show_name}
                            checked={category.show_name}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 21 }}>
                        <ToogleField
                            name={`exclude_from_search`}
                            label={"Exclude from search"}
                            style={{ width: 60 }}
                            value={category.exclude_from_search}
                            checked={category.exclude_from_search}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 21 }}>
                        <ToogleField
                            name={`exclude_from_selection`}
                            label={"Exclude from selection"}
                            style={{ width: 60 }}
                            value={category.exclude_from_selection}
                            checked={category.exclude_from_selection}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2" style={{ paddingTop: 21 }}>
                        <ToogleField
                            name={`exclude_from_menu`}
                            label={"Exclude from menu"}
                            style={{ width: 60 }}
                            value={category.exclude_from_menu}
                            checked={category.exclude_from_menu}
                        />
                    </FlexboxGrid.Item>


                </FlexboxGrid>
                <FlexboxGrid className="pb-2">


                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <SelectField
                            name={"display"}
                            placeholder="Select Display Template..."
                            searchable={false}
                            data={Category.DisplayTypes}
                            labelKey={"label"}
                            valueKey={"value"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Display Template"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={"style"}
                            placeholder="Select Style..."
                            searchable={false}
                            data={Category.StyleTypes}
                            labelKey={"label"}
                            valueKey={"value"}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"Product & Category style"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <CascadeSelectField
                            name="parent"
                            placeholder={'Select Parent Category...'}
                            label={"Parent Category"}
                            data={convertCategoriesForFilter(convertToTree(categories))}
                            style={{ width: 200 }}
                            valueKey={'id'}
                            menuWidth={200}
                            parentSelectable={true}
                            preventOverflow={true}
                            onChange={(value, e) => {
                                //e.preventDefault();
                                category.parent = genObjectFromId(value, categories)
                                console.log(category.parent)
                                handleFormChange(category, ftype)
                            }}
                            value={category.parent ? category.parent.id : null}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <NumberField
                            name="priority"
                            placeholder={'0'}
                            label={"Priority"}
                            style={{ width: 100 }}
                            onChange={(value) => {
                                category.priority = value ? parseInt(value) : 0;
                                handleFormChange(category, ftype);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="ai_name_pattern" label="Product Name Patter AI" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid>
                    <FlexboxGrid.Item className="pb-2">
                        Description
                        <TextEditorField
                            value={category.description ? category.description : ""}
                            handleOnChange={(content) => {
                                category.description = content
                                handleFormChange(category, ftype);
                            }}
                            height={150}
                        //width={500}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-3">
                        <TextField
                            name="meta_description" label="Meta Description (limit 160 chars)"
                            componentClass="textarea"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <Divider className="my-3">Images</Divider>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <ImageUploader
                            object={category}
                            object_type={ftype}
                            assignAction={handleAssignImageFile}
                            deleteAction={handleDeleteImageFile}
                            name_prefix="Category"
                            thumb_url={category.image ? category.image.thumb : null}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-3">
                        <CodeEditorField
                            name={"icon"}
                            label={"Icon (as .svg)"}
                            value={category.icon ? category.icon : ""}
                            handleOnChange={(content) => {
                                category.icon = content.target.value
                                handleFormChange(category, ftype);
                            }}
                            height={150}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <Divider className="my-3">Attributes</Divider>

                {category.category_filters.length > 0 ?
                    <FlexboxGrid className="pt-1">
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 475 }}>
                            {"Filter"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 90 }}>
                            {"Priority"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 90 }}>
                            {"Featured"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 110 }}>
                            {"Filter"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 90 }}>
                            {"FilterID"}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}

                {category.category_filters ?
                    category.category_filters.map((cf, index) => (
                        <FlexboxGrid className="pt-1" key={"category_filters" + index}>


                            <FlexboxGrid.Item className="pr-2">
                                <CascadeSelectField

                                    placeholder={'Select Filter...'}
                                    data={convertAttrAndCatsForFilter(convertToTree(attributes_categories), attributes)}
                                    style={{ width: 450 }}
                                    valueKey={'id'}
                                    menuWidth={323}
                                    preventOverflow={true}
                                    onChange={(value) => {
                                        if (!value || !value.includes("."))
                                            return;
                                        const cat_id = parseInt(value.split(".")[0]);
                                        const att_id = parseInt(value.split(".")[1]);
                                        category.category_filters[index].attribute_category = genObjectFromId(cat_id, attributes_categories)
                                        category.category_filters[index].attribute = genObjectFromId(att_id, attributes)
                                        handleFormChange(category, ftype)
                                    }}
                                    renderValue={(value, itemPaths, selectedElement) => {
                                        const lastTwoPaths = itemPaths.slice(-2);
                                        const displayPaths = lastTwoPaths.map(path => path.label);
                                        return displayPaths.join(' / ');
                                    }}
                                    value={
                                        category.category_filters[index].attribute_category &&
                                            category.category_filters[index].attribute_category.id &&
                                            category.category_filters[index].attribute &&
                                            category.category_filters[index].attribute.id
                                            ? category.category_filters[index].attribute_category.id + "." + category.category_filters[index].attribute.id : ""}
                                />
                            </FlexboxGrid.Item>





                            <FlexboxGrid.Item className="pr-2">
                                {cf.attribute_category && cf.attribute_category.id ?
                                    <NumberField
                                        name={`category_filters.${index}.priority`}
                                        placeholder={'0'}
                                        //label={"Priority"}
                                        style={{ width: 80 }}
                                        value={cf.priority}
                                        onChange={(value) => {
                                            category.category_filters[index].priority = value ? parseInt(value) : 0;
                                            handleFormChange(category, ftype);
                                        }}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                {cf.attribute_category && cf.attribute_category.id ?
                                    <ToogleField
                                        name={`category_filters.${index}.featured`}
                                        style={{ width: 60 }}
                                        value={cf.featured}
                                        checked={cf.featured}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                {cf.attribute_category && cf.attribute_category.id ?
                                    <ToogleField
                                        name={`category_filters.${index}.filter`}
                                        style={{ width: 60 }}
                                        value={cf.filter}
                                        checked={cf.filter}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`category_filters`, index, ftype)}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Filter"}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                {cf.attribute ?
                                    <Badge content={generateFilterIDPrint(cf.attribute)} style={{ background: '#eee', padding: "2px 10px", color: "black" }} />
                                    : ""}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ))

                    : ""}
                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`category_filters`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Attribute</IconButton>

                {category.category_filters.length == 0 ?
                    <FlexboxGrid.Item className='pt-4'>
                        <CascadeSelectField
                            name="parent"
                            placeholder={'Select Category...'}
                            label={"Copy attributes from other categories"}
                            data={convertCategoriesForFilter(categories)}
                            style={{ width: 200 }}
                            valueKey={'id'}
                            menuWidth={200}
                            parentSelectable={true}
                            preventOverflow={true}
                            onChange={(value, e) => {
                                let selected_cat = genObjectFromId(value, categories)
                                let filters = cloneDeep(selected_cat.category_filters)
                                let new_filters = []
                                for (let filter of filters) {
                                    delete filter.id
                                    new_filters.push(filter)
                                }
                                category.category_filters = new_filters
                                handleFormChange(category, ftype)
                            }}
                        />
                    </FlexboxGrid.Item> : ""}


                <Divider className="my-3">Apply Custom Parameters</Divider>
                {category.category_custom_parameters.length > 0 ?
                    <FlexboxGrid className="pt-1">
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 170 }}>
                            {"Parameter"}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="pr-2 font-weight-bold" style={{ width: 150 }}>
                            {"Value"}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}
                {category.category_custom_parameters ?
                    category.category_custom_parameters.map((cp, index) => (
                        <FlexboxGrid className="pt-1" key={"category_custom_parameters" + index}>
                            <FlexboxGrid.Item className="pr-2">
                                <CascadeSelectField
                                    name={`category_custom_parameters.${index}.parameter`}
                                    placeholder={'Select Parameter'}
                                    style={{ width: 150 }}
                                    data={Category.CustomParams}
                                    valueKey={'vkey'}
                                    menuWidth={150}
                                    preventOverflow={true}
                                    onChange={(value) => {
                                        category.category_custom_parameters[index].parameter = value
                                        handleFormChange(category, ftype)
                                    }}

                                    value={cp.parameter}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pr-2">
                                <TextField
                                    name={`category_custom_parameters.${index}.value`}
                                    style={{ width: 200 }}
                                    value={cp.value}
                                    message={findTooltip(cp.parameter, Category.CustomParams)}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className="pr-2">
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => removeFormRow(`category_custom_parameters`, index, ftype)}
                                    icon={<Icon icon="trash" />}
                                    title={"Delete Parameter"}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ))


                    : ""}
                <IconButton
                    className="mt-2"
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`category_custom_parameters`, ftype)}
                    icon={<Icon icon="plus-square-o" />}
                >Add Custom Parameter</IconButton>
            </Form>
        </>
    );

}

const findTooltip = (vkey, predefined_custom_params) => {
    let tooltip = "";
    predefined_custom_params.map((pcp) => {
        if (pcp.vkey === vkey) {
            tooltip = pcp.tooltip
        }
        return pcp;
    })
    return tooltip
}




export default CategoriesForm;