import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Form, Radio, Divider, FlexboxGrid,
    IconButton, Icon, Tag,
    SelectPicker
} from 'rsuite';

import { TextField, RadioField, MultiSelectField, NumberField, MultiCascadeSelectField } from '../_common/form-fields';

import { Product } from '../products/products-const'
import { slugify, getSelectionValues, genObjectsFromIds, convertCategoriesForFilter, convertToTree, genCatObjectsFromIds, namePrediction, decodeHtml, categoriesPrediction, hasPermission } from '../../utils/utils'

import SortableProductVariationList from "./_common/product-variation-list"

import Modal from '../_base/modal/modal-container';
import { showModal, closeModal } from '../_base/modal/modal-action';

import axios from 'axios';

const MapperForm = ({
    product, model, scraper_products,
    categories, brands, shops,
    handleFormChange, ftype,
    formError, available_websites,
    handleAssignImageFile,
    handleDeleteImageFile,
    addFormRow, removeFormRow,
    handleReorderProductVariations,
    handleReorderImages,
    attributes, attributes_categories,
    handleConnectToExsitingAsBarcode,
    handleNameChange, handleConnectToExsiting,
    related_products, existing_products, existing_product,
    handleVariationChange, handleRefreshInputs,
    selected_website,
    handleMergeScraped,
    handleReorderDocuments,
    handleAssignDocumentFile,
    handleDeleteDocumentFile,
    current_user
}) => {

    const dispatch = useDispatch();
    const [variantMergeId, setMegeId] = useState(0);
    const [initialAIGeneration, setInitialAIGeneration] = useState(false);
    const [aiNamePattern, setAINamePattern] = useState("");
    const [aiNameLoader, setAiNameLoader] = useState(false);
    const [selectedCat, setSelectedCat] = useState(null);

    useEffect(() => {
        if (product.categories && product.categories.length > 0) {
            let name_pattern = "";
            let selected_cat = null;
            for (const cat of product.categories) {
                if (cat && cat.ai_name_pattern && cat.ai_name_pattern != "") {
                    name_pattern = cat.ai_name_pattern
                    selected_cat = cat.id
                }
            }
            if (name_pattern) {
                setAINamePattern(name_pattern)
                setSelectedCat(selected_cat)
            }
        }
    }, [product.categories, initialAIGeneration])


    useEffect(() => {

        if (!initialAIGeneration && aiNamePattern != "") {
            setInitialAIGeneration(true)
            handleGetAiNamePrediction()
        }

    }, [aiNamePattern])

    const convertForSelection = (variations) => {
        let data = [];

        variations.forEach((v, i) => {
            data.push({ value: i, label: v.name })
        });
        return data
    }

    const handleGetAiNamePrediction = async () => {
        setAiNameLoader(true)
        let messages = [];
        let combined_names = "";

        if (scraper_products && scraper_products.length > 0) {
            for (const pr of scraper_products) {
                combined_names += decodeHtml(pr.name) + "\n"
            }
        }

        //[NAZIV BRENDA] bazen za dvorište [dimezije ŠxVxD][cm] [naziv modela] [šifra modela]

        messages.push({ "role": "system", "content": "You are a helpful assistant that generate JSON. Do not include any explanations, only provide a  RFC8259 compliant JSON response following this format without deviation. You should return results in Serbian language. You have expert knowledge in home appliances, IT equipment and retail. You are able to take information from different sources, analyze it, and return only what is true and accurate." })

        messages.push({
            role: "user",
            content:
                "Generate the name based on following pattern:\n" +
                aiNamePattern + "\n" +
                "Here are the inputs how other shops named that product:" +
                combined_names + "\n" +
                'You should return result in following json format {"name":"PRODUCT_NAME"}, brand name write all in capital letters.'
        })

        //console.log(messages)

        const response = await axios.post("https://api.openai.com/v1/chat/completions",
            {
                "temperature": 0,
                "n": 1,
                "model": "gpt-4o",
                "messages": messages
            }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${selected_website.open_ai_token}`,
            },
        })
        if (response && response.data && response.data.choices && response.data.choices[0] && response.data.choices[0].message && response.data.choices[0].message.content) {
            try {
                let json = JSON.parse(response.data.choices[0].message.content);
                if (json && json.name) {

                    product.name = json.name
                    if (!product.multi_variant) {
                        product.product_variations[0].name = json.name
                        product.product_variations[0].slug =
                            slugify(product.product_variations[0].name) +
                            (selected_website.product_slug_addition ? selected_website.product_slug_addition : "")
                    }
                    handleNameChange(product.name);
                    handleFormChange(product, ftype)
                }
                setAiNameLoader(false)
            } catch (err) { setAiNameLoader(false) }
        }
    }

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) => {
                    if (existing_product.id)
                        handleFormChange(formValue, "edit")
                    else
                        handleFormChange(formValue, "new")
                }}
                checkTrigger={"blur"}
                formError={formError}
                formValue={existing_product && existing_product.id ? existing_product : product}
                className={"product-form mapper-form"}
            >
                <Divider className="mt-1">General</Divider>

                <FlexboxGrid className="pb-3">
                    <FlexboxGrid.Item className="pr-2">
                        <MultiCascadeSelectField
                            cascade={false}
                            name={"categories"}
                            placeholder="Select Categories..."
                            searchable={true}
                            data={convertCategoriesForFilter(convertToTree(categories))}
                            //labelKey={"name"}
                            valueKey={"id"}
                            preventOverflow={true}
                            style={{ width: 500 }}
                            menuWidth={200}
                            label={"Categories"}
                            value={existing_product.id ? getSelectionValues(existing_product.categories) : getSelectionValues(product.categories)}
                            onChange={(values) => {
                                product.categories = genCatObjectsFromIds(values, categories)
                                handleFormChange(product, ftype)
                            }}
                            disabled={existing_product.id ? true : false}
                        />
                    </FlexboxGrid.Item>
                    {!existing_product.id ?
                        <FlexboxGrid.Item className="pl-2 pt-2">
                            <IconButton
                                size="md"
                                placement="left"
                                onClick={() => {
                                    product.categories = categoriesPrediction(scraper_products, categories)
                                    handleFormChange(product, ftype)
                                }}
                                icon={<Icon icon="magic" />}
                            />
                        </FlexboxGrid.Item>
                        : ""}
                    <FlexboxGrid.Item className="pl-2 pt-2">
                        <IconButton
                            size="md"
                            placement="left"
                            onClick={() => {
                                handleRefreshInputs()
                            }}
                            icon={<Icon icon="refresh" />}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-3">
                    {!existing_product.id ?
                        <FlexboxGrid.Item className="pl-2">
                            <table style={{ border: "1px solid rgba(0,0,0,0.2)", marginTop: 10 }}>
                                <thead>
                                    <tr>
                                        {scraper_products ? scraper_products.map((prod, index) => (
                                            prod.categories ?
                                                <th className="text-center" key={"scnh" + index} style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                                                    {prod.shop_id}
                                                </th>
                                                : ""

                                        )) : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {scraper_products ? scraper_products.map((prod, index) => (
                                            prod.categories ?
                                                <td key={"scn" + index} style={{ border: "1px solid rgba(0,0,0,0.2)", maxWidth: 100, padding: 5 }}>
                                                    {prod.categories ? prod.categories.map((cat, index) => (
                                                        <div key={"scnc" + index}>
                                                            {(index + 1) + ") " + cat}
                                                        </div>
                                                    )) : ""}
                                                </td>
                                                : ""
                                        )) : ""}
                                    </tr>
                                </tbody>
                            </table>
                        </FlexboxGrid.Item>
                        : ""}
                </FlexboxGrid>


                <FlexboxGrid className="pb-2">
                    {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                        <>
                            <FlexboxGrid.Item>
                                <RadioField name="status" className="inline-radio" appearance="picker" inline label={"Status"} disabled={existing_product.id ? true : false}>

                                    <Radio disabled={existing_product.id ? true : false} value={Product.Status.ACTIVE}>Active</Radio>


                                    <Radio disabled={existing_product.id ? true : false} value={Product.Status.DRAFT}>Draft</Radio>
                                    <Radio disabled={existing_product.id ? true : false} value={Product.Status.ARCHIVED}>Archived</Radio>
                                </RadioField>
                            </FlexboxGrid.Item>

                            {/* <FlexboxGrid.Item className="pl-2 d-none">
                                <NumberField
                                    name="priority"
                                    placeholder={'0'}
                                    label={"Priority"}
                                    style={{ width: 100 }}
                                    onChange={(value) => {
                                        product.priority = value ? parseInt(value) : 0;
                                        handleFormChange(product, ftype);
                                    }}
                                    disabled={existing_product.id ? true : false}
                                />
                            </FlexboxGrid.Item> */}

                            {/* <FlexboxGrid.Item className="pl-2">
                                <MultiSelectField
                                    name={"websites"}
                                    placeholder="Select Associated Websites"
                                    //searchable={true}
                                    data={available_websites}
                                    labelKey={"name"}
                                    valueKey={"id"}
                                    preventOverflow={true}
                                    style={{ width: 200 }}
                                    label={"Associated Websites"}
                                    value={existing_product.id ? getSelectionValues(existing_product.websites) : getSelectionValues(product.websites)}
                                    onChange={(values, e) => {
                                        e.preventDefault();
                                        product.websites = genObjectsFromIds(values, available_websites)
                                        handleFormChange(product, ftype)
                                    }}
                                    disabled={existing_product.id ? true : false}
                                />
                            </FlexboxGrid.Item> */}
                        </>
                        : ""}

                </FlexboxGrid>


                <FlexboxGrid>
                    <FlexboxGrid.Item>
                        <div className='d-flex'>
                            <TextField
                                name="name"
                                label="Name"
                                letterCount
                                style={{ width: 600 }}
                                value={existing_product.id ? existing_product.name : product.name}
                                onChange={(value) => {
                                    product.name = value ? value : "";
                                    if (!product.multi_variant) {
                                        product.product_variations[0].name = value;
                                        product.product_variations[0].slug =
                                            slugify(product.product_variations[0].name) +
                                            (selected_website.product_slug_addition ? selected_website.product_slug_addition : "")
                                    }

                                    handleNameChange(product.name);
                                    handleFormChange(product, ftype);
                                }}
                                disabled={existing_product.id || product.categories.length == 0 || aiNameLoader ? true : false}
                            />
                            {!existing_product.id && product.categories.length > 0 && aiNamePattern ?
                                <IconButton
                                    className='mt-2'
                                    size="lg"
                                    placement="left"
                                    onClick={() => {
                                        handleGetAiNamePrediction();
                                    }}
                                    icon={<Icon icon="magic" />}
                                    loading={aiNameLoader}
                                />
                                : ""}
                        </div>
                        {!existing_product.id ?
                            <table style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                                <tbody>
                                    {scraper_products ? scraper_products.map((prod, index) => (
                                        <tr key={"scn" + index}>
                                            <td style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 5 }}>
                                                {prod.shop_id}
                                            </td>
                                            <td style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 5 }}>
                                                {decodeHtml(prod.name)}
                                            </td>
                                            <td style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 5 }}>
                                                <a href={prod.url} target="_blank"> {"Link >>"}</a>
                                            </td>
                                        </tr>
                                    )) : ""}
                                </tbody>
                            </table>
                            : ""}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-2 abs-image ">
                        {scraper_products && scraper_products[0] && scraper_products[0].images && scraper_products[0].images[0] ?
                            <div className="large-thumb">
                                <img
                                    className="img-thumbnail img-fluid"
                                    alt={"pimage"}
                                    src={scraper_products[0].images[0]}
                                />
                            </div>
                            : ""}
                    </FlexboxGrid.Item>

                </FlexboxGrid>





                <FlexboxGrid className="pt-3 mapper-existing-products">
                    <FlexboxGrid.Item className="pr-2 section-mapper" style={{ width: "50%" }}>
                        {product.name && !existing_product.id && product.categories.length > 0 ?
                            <> {product.name && !existing_product.id ?
                                <>
                                    <Divider className="my-3">Mapped product</Divider>
                                    <div className="pb-2">
                                        {related_products.map((pr, index) => (
                                            !existing_product.id ?
                                                <Tag key={"exs" + index} className="mb-2 mx-2">

                                                    {pr.images && pr.images[0] ?
                                                        <div className="img">
                                                            <img src={pr.images[0]} alt="thumb" width="25" height="25" className="mr-2" />
                                                        </div>
                                                        : ""}
                                                    <div className="name">
                                                        <a href={pr.url} target="_blank">
                                                            {pr.name}
                                                        </a>
                                                    </div>
                                                    <div className="shop ml-auto">{pr.shop_id}</div>
                                                    {product.multi_variant ?
                                                        <SelectPicker
                                                            data={convertForSelection(product.product_variations)}
                                                            onChange={(value) => setMegeId(value)}
                                                            value={variantMergeId}
                                                            size={"sm"}
                                                        />
                                                        : ""}
                                                    <IconButton
                                                        className="ml-2 btn-merge"
                                                        //color="gray"
                                                        size="xs"
                                                        placement="left"
                                                        onClick={() => handleMergeScraped(pr, variantMergeId)}
                                                        icon={<Icon icon="compress" />}
                                                    >Merge Details</IconButton>



                                                    <IconButton
                                                        className="ml-2 btn-add"
                                                        color="green"
                                                        size="xs"
                                                        placement="left"
                                                        onClick={() => {
                                                            if (!product.multi_variant) {
                                                                product.multi_variant = true;
                                                                handleFormChange(product, ftype)
                                                            }
                                                            handleVariationChange(pr, "add");
                                                        }}
                                                        icon={<Icon icon="plus-square" />}
                                                    >Add Variation</IconButton>


                                                </Tag>
                                                : ""
                                        ))}
                                    </div>
                                </>
                                : ""}

                            </>
                            : ""}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2 section-mapper" style={{ width: "50%" }}>
                        {product.name && !existing_product.id && product.categories.length > 0 ?
                            <>
                                <Divider className="my-3">Connect to Existing Product</Divider>
                                <div className="pb-2">
                                    {existing_products.map((pv, index) => (
                                        pv.id.startsWith("variation_") && !existing_product.id ?
                                            <Tag key={"exs" + index} className="mb-2 mx-2">
                                                {pv && pv.thumb ?
                                                    <img src={pv.thumb} alt="thumb" width="25" height="25" className="mr-2" />
                                                    : ""}
                                                {pv.name}

                                                {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                                    <>
                                                        <IconButton
                                                            className="ml-auto"
                                                            //color="green"
                                                            size="xs"
                                                            placement="left"
                                                            onClick={() => { dispatch(showModal("connect_to_existing_barcodes_" + pv.id)) }}
                                                            icon={<Icon icon="plus-square" />}
                                                        >Merge barcodes</IconButton>
                                                        <Modal
                                                            type={"connect_to_existing_barcodes_" + pv.id}
                                                            title={"Connect barcodes to existing product"}
                                                            handleAction={() => {
                                                                handleConnectToExsitingAsBarcode(pv.pid, pv.id.split('_')[1])
                                                                dispatch(closeModal("connect_to_existing_barcodes_" + pv.id))
                                                            }}
                                                            size="xs"
                                                        >
                                                            {`Are you sure you want to map details and barcodes from  ${product.name} to ${pv.name}?`}
                                                        </Modal>
                                                    </>
                                                    : ""}

                                                {hasPermission(current_user, ["ROLE_MODERATOR", "ROLE_PRICE_MODERATOR"]) ?
                                                    <IconButton
                                                        className="ml-2"
                                                        color="green"
                                                        size="xs"
                                                        placement="left"
                                                        onClick={() => { handleConnectToExsiting(pv.pid) }}
                                                        icon={<Icon icon="plus-square" />}
                                                    >Add Variation</IconButton>
                                                    : ""}

                                            </Tag>
                                            : ""
                                    ))}
                                </div>
                            </>
                            : ""}
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                {product.name && product.categories.length > 0 ?
                    <>
                        <Divider className="my-3">Variations</Divider>
                        <SortableProductVariationList
                            helperClass="sortableHelper"
                            useDragHandle
                            ftype={existing_product.id ? "edit" : "new"}
                            product_variations={existing_product.id ? existing_product.product_variations : product.product_variations}
                            addFormRow={addFormRow}
                            removeFormRow={removeFormRow}
                            multi_variant={existing_product.id ? existing_product.multi_variant : product.multi_variant}
                            brands={brands}
                            shops={shops}
                            handleFormChange={handleFormChange}
                            handleAssignImageFile={handleAssignImageFile}
                            handleDeleteImageFile={handleDeleteImageFile}
                            handleReorderImages={handleReorderImages}
                            handleVariationChange={handleVariationChange}
                            product={existing_product.id ? existing_product : product}
                            attributes={attributes}
                            attributes_categories={attributes_categories}
                            available_websites={available_websites}
                            categories={product.categories}
                            handleRefreshInputs={handleRefreshInputs}
                            onSortEnd={({ oldIndex, newIndex }) => {
                                handleReorderProductVariations(oldIndex, newIndex, ftype);
                            }}
                            formError={formError}
                            selected_website={selected_website}
                            handleAssignDocumentFile={handleAssignDocumentFile}
                            handleDeleteDocumentFile={handleDeleteDocumentFile}
                            handleReorderDocuments={handleReorderDocuments}
                            current_user={current_user}
                            selectedCat={selectedCat}
                        />
                    </>
                    : ""}

            </Form>
        </>
    );

}




export default MapperForm;